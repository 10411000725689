/* import './index.css'; */
import './App.css';
import Navbar from './components/Navbar/navbar';
import InfoDesc from './components/InfoDesc/InfoDesc';
import Home from './components/Home/home';
import Vehiculos from './components/Vehiculos/vehiculos';
import Destinos from './components/Destinos/destinos';
import Footer from './components/Footer/footer';
import Whatapp from './components/Whatapp/whatapp';
import Componente from './components/Contacto/contacto';

function App() {
  return (
    <>
      <Navbar/>
      <Home/>
      <InfoDesc />
      <Vehiculos/>
      <Destinos/>
      <Componente/>
      <Footer/>
      <Whatapp/>
    </>
  );
}
export default App;
