import React from 'react';
import styles from'./whatapp.module.css';
import logo1 from '../../assets/whatsapp.png';


export default function whatapp() {
  const openWhatsAppChat = () => {
    const phoneNumber = '2644892393'; // Reemplaza con el número de teléfono deseado
    const whatappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatappLink, '_blank');
  };

  return (
    <div className={styles.logow} onClick={openWhatsAppChat}>
      <img src={logo1} alt="Logo" />
    </div>
  );
}
