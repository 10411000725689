import React from 'react'
import styles from './home.module.css';



export default function home() {
  return (
    <>
      <div className={styles.hero}>

        <div className={styles.h1}>
          <h1>Conectando destinos</h1>
        </div>

        <video className={styles.video} width="640" height="480" src={require('../../assets/paisaje-sj 720p.mp4')}
          type='video/mp4' autoPlay muted loop preload='auto'>
        </video>
      </div>


      {/* <div className={styles.containerhome}>

        <h2 className={styles.h2}>
          Descubre el transporte de excelencia desde San Juan para alcanzar tu destino ideal con comodidad y eficiencia,Donde Nos comprometemos a brindar un servicio de calidad que va más allá de tus expectativas. Con nosotros, cada viaje se convierte en una experiencia única y satisfactoria. ¡Disfruta del trayecto con la confianza de llegar de la mejor manera posible!</h2>

        <h3 className={styles.h3}>
          Nuestros Servicios Incluyen: <br />.Viajes de corta y larga distancia.<br />
          .Tours turísticos a puntos de interés.<br />
          .Viajes especializados en deportes, eventos sociales, culturales, religiosos y familiares.<br />
       
        </h3>


      </div> */}
    </>

  )
}


