import styles from './InfoDesc.module.css';
import social from '../../assets/social_info.png';
import turismo from '../../assets/turismo_info.png';
import bus from '../../assets/transporte_info.png';


export default function TuComponente() {



    return (
        <>
            <div className={styles.cont_desc}>
                <h2 className={styles.cont_h2} >Brindamos un servicio de calidad que va más allá de tus expectativas. <br />¡Disfruta del trayecto con la confianza de llegar de la mejor manera!</h2>
                <div className={styles.card_desc}>
                    <div  className={styles.card_desc_ind}>
                        <img src={bus} alt="" />
                        <h4>Viajes de corta y larga distancia</h4>
                        <p>Alcanza tu destino ideal con comodidad y eficiencia</p>
                    </div>

                    <div  className={styles.card_desc_ind}>
                    <img src={turismo} alt="" />
                        <h4>Tours turísticos a puntos de interés</h4>
                        <p>Con nosotros, cada viaje se convierte en una experiencia única y satisfactoria.</p>
                    </div>

                    <div  className={styles.card_desc_ind}>
                    <img src={social} alt="" />
                        <h4>Viajes especializados</h4>
                        <p>Deportes, eventos sociales, culturales, religiosos y familiares</p>
                    </div>
                </div>
            </div>
        </>
    );
}