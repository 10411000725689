import React, { useState } from "react";
import styles from "./destinos.module.css";
import destino_1 from "../../assets/dique-de-ullum.jpg";
import destino_2 from "../../assets/pampa-el-leoncito.jpg";
import destino_3 from "../../assets/talampaya3.jpg";
import destino_4 from "../../assets/valle-de-la-luna.jpg";
import destino_5 from "../../assets/dique-punta-negra.jpg";
import destino_6 from "../../assets/vista-parcial-del-jardin.jpg";

const nombresDestinos = [
  "Dique de Ullúm",
  "Pampa el Leoncito",
  "Talampaya",
  "Valle de la Luna",
  "Dique de Punta Negra",
  "Jardín de los Poetas"
];

const descripciones = [
  "Belleza de la naturaleza al aire libre",
  "Para disfrutar de la tranquilidad y la belleza del desierto",
  "Joya natural de cañones rocosos y formaciones geológicas únicas",
  "Lugar mágico con formaciones rocosas que crean un importante sitio arqueológico",
  "Impresionante entorno natural rodeado de montañas y paisajes escénicos",
  "Lugar inspirador que invita a la contemplación de la poesía y esculturas"
];

function Destinos() {
  const [flipped, setFlipped] = useState(Array(6).fill(false));

  const flipCard = (index) => {
    const newFlipped = [...flipped];
    newFlipped[index] = !newFlipped[index];
    setFlipped(newFlipped);
  };

  const urlsDestinos = [
    "https://www.tripadvisor.com.ar/Attraction_Review-g312785-d4736859-Reviews-Dique_de_Ullum-San_Juan_Province_of_San_Juan_Cuyo.html",
    "https://sanjuan.tur.ar/vivi-san-juan/pampa-el-leoncito",
    "https://www.tripadvisor.com.ar/AttractionProductReview-g312785-d21095048-From_San_Juan_Talampaya_National_Park_with_internal_circuit_inc-San_Juan_Province_.html",
    "https://www.tripadvisor.com.ar/Attraction_Review-g312784-d553113-Reviews-The_Valley_of_the_Moon-Province_of_San_Juan_Cuyo.html",
    "https://www.tripadvisor.com.ar/Attraction_Review-g312785-d13509432-Reviews-Punta_Negra_Dam-San_Juan_Province_of_San_Juan_Cuyo.html",
    "https://www.tripadvisor.com.ar/LocationPhotoDirectLink-g312785-d14039227-i317684133-Poetas_Garden-San_Juan_Province_of_San_Juan_Cuyo.html",
  ];

  return (
    <>
      <div className={styles.titleDestinos} id="Destinos">
        <span>DESTINOS </span> <span className={styles.preferidos}> PREFERIDOS</span>
      </div>

      <div className={styles.contenedor2}>
        {Array(6).fill().map((_, index) => (
          <div
            key={index}
            className={`${styles.contenedor_cards} ${flipped[index] ? styles.flipped : ""}`}
            onClick={() => flipCard(index)}
          >
            <div className={styles.front}>
              <img
                className={styles.img_cards}
                src={index === 0 ? destino_1 : index === 1 ? destino_2 : index === 2 ? destino_3 : index === 3 ? destino_4 : index === 4 ? destino_5 : destino_6}
                alt={`Destino ${index + 1}`}
              />
              <div className={styles.text}>
                <h2 className={styles.title_card}>
                  <a href={urlsDestinos[index]} target="_blank">
                    {nombresDestinos[index]}
                  </a>
                </h2>
                <h3 className={styles.description_card}>
                  {descripciones[index]}
                </h3>
              </div>
            </div>
            <div className={styles.back}>
              <a className={styles.linkss} href={urlsDestinos[index]} target="_blank">
                Más información
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Destinos;
