import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './vehiculos.css'


import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';


// import required modules
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';

import slide_image_1 from '../../assets/2008blanca22.jpg';
import slide_image_2 from '../../assets/jumpy3.jpg';
import slide_image_3 from '../../assets/mercedes.jpg';
import slide_image_5 from '../../assets/v6.jpg';
import slide_image_6 from '../../assets/v5.jpg';

function Vehiculos() {
  return (
    <>
      <div className='container'>
        <div className="titleGaleria" id='Unidades'>
          <div ><span className='nuestras'>NUESTRAS</span> <span className='traslado'> UNIDADES</span></div>
        </div>

        <Swiper
          spaceBetween={0}
          autoplay={2000}
          slidesPerView={2}
          loop={true}
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={{ el: '.swiper-pagination', clickable: true }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            clickable: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
        >
          <SwiperSlide >
            <img src={slide_image_1} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_2} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_3} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_5} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide_image_6} alt="slide_image" />
          </SwiperSlide>

          <div className="slider-controler">
            <div className="swiper-button-prev slider-arrow">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <div className="swiper-button-next slider-arrow">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>

      </div>



    </>
  );
}

export default Vehiculos;